import { Link } from "gatsby"
import React from "react"
import { AiOutlinePaperClip } from "react-icons/ai"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const HybridProgrammingModelPage = () => (
  <Layout>
    <SEO
      title="Hybrid Programming Model - PCS"
      description=""
      lang="en"
      meta={[]}
    />
    {/* Hero */}
    <div className="banner-image project hybrid">
      <div className="hero-text">
        <h1>Hybrid Programming Model</h1>
      </div>
    </div>

    {/* Main Project Section */}
    <section className="bg-darkened main-project-section">
      <div className="container">
        <div className="breadcrumb">
          <Link to="/pcs/projects">Projects</Link> <span>&gt;</span>
          <Link to="/pcs/projects/hybrid-programming-model">
            Hybrid Programming Model
          </Link>
        </div>
        <h2>Hybrid Programming Model</h2>

        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel, esse.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
          dignissimos doloremque reprehenderit officiis voluptate dolorem, quos
          odit voluptatum praesentium dolore culpa possimus laborum natus
          obcaecati. Voluptates optio commodi explicabo fugiat debitis dicta
          fugit vero alias odit delectus perspiciatis itaque a repellendus
          repudiandae ratione nobis voluptas nulla facere nihil, nemo doloribus
          hic. Tempore distinctio corporis officiis debitis ipsum obcaecati?
          Culpa vel odit impedit accusantium fugiat nesciunt ut, voluptas in
          aspernatur! Ex praesentium laboriosam numquam vel mollitia fugiat
          nobis commodi eos cumque, maiores soluta, quisquam et cum, error at
          magni necessitatibus inventore atque eius labore totam dolorum!
        </p>

        <p>
          Nobis voluptas optio iste, culpa illum saepe delectus modi doloremque
          libero molestiae in hic cum alias ducimus tempora recusandae est quam
          eos nesciunt veniam dicta? Quas, mollitia! Magni, sunt non repellendus
          amet quasi doloremque nobis dicta, quos animi odit ad nam. Quo,
          expedita dolores culpa ullam est fugiat. Vitae facere perferendis
          voluptas nobis debitis placeat est atque tempore expedita unde
          quibusdam eveniet eius, facilis aliquid consequatur obcaecati ratione
          at deleniti. Rem alias, modi error, maiores consequuntur quaerat autem
          porro optio deleniti culpa deserunt harum tenetur iusto praesentium
          voluptatibus facere quo vero distinctio ratione voluptate obcaecati,
          eos quibusdam illum omnis. Nemo possimus rerum iste quaerat
          recusandae!
        </p>
      </div>
    </section>

    {/* Sub Project Section */}
    <section className="sub-project-section">
      <div className="container">
        <div className="img-container">
          <div className="img-div one"></div>
        </div>
        <Link
          id="pgas-open-shmem"
          to="/pcs/projects/hybrid-programming-model/#pgas-open-shmem"
        >
          <h2>
            PGAS/OpenSHMEM
            <AiOutlinePaperClip />
          </h2>
        </Link>

        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel, esse.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
          dignissimos doloremque reprehenderit officiis voluptate dolorem, quos
          odit voluptatum praesentium dolore culpa possimus laborum natus
          obcaecati. Voluptates optio commodi explicabo fugiat debitis dicta
          fugit vero alias odit delectus perspiciatis itaque a repellendus
          repudiandae ratione nobis voluptas nulla facere nihil, nemo doloribus
          hic. Tempore distinctio corporis officiis debitis ipsum obcaecati?
          Culpa vel odit impedit accusantium fugiat nesciunt ut, voluptas in
          aspernatur! Ex praesentium laboriosam numquam vel mollitia fugiat
          nobis commodi eos cumque, maiores soluta, quisquam et cum, error at
          magni necessitatibus inventore atque eius labore totam dolorum!
        </p>
      </div>
    </section>

    {/* Sub Project Section */}
    <section className="bg-darkened sub-project-section">
      <div className="container">
        <div className="img-container">
          <div className="img-div one"></div>
        </div>
        <Link
          id="hybrid-spmd-mpsd"
          to="/pcs/projects/hybrid-programming-model/#hybrid-spmd-mpsd"
        >
          <h2>
            Hybrid SPMD-MPSD
            <AiOutlinePaperClip />
          </h2>
        </Link>

        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel, esse.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
          dignissimos doloremque reprehenderit officiis voluptate dolorem, quos
          odit voluptatum praesentium dolore culpa possimus laborum natus
          obcaecati. Voluptates optio commodi explicabo fugiat debitis dicta
          fugit vero alias odit delectus perspiciatis itaque a repellendus
          repudiandae ratione nobis voluptas nulla facere nihil, nemo doloribus
          hic. Tempore distinctio corporis officiis debitis ipsum obcaecati?
          Culpa vel odit impedit accusantium fugiat nesciunt ut, voluptas in
          aspernatur! Ex praesentium laboriosam numquam vel mollitia fugiat
          nobis commodi eos cumque, maiores soluta, quisquam et cum, error at
          magni necessitatibus inventore atque eius labore totam dolorum!
        </p>
      </div>
    </section>
  </Layout>
)

export default HybridProgrammingModelPage
